// Icon
import React from "react"

function Icon() {
  return (
    <svg
      id="Laag_1"
      data-name="Laag 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.48 22.57"
    >
      <path
        d="M15.86,13.42a.5.5,0,0,0-.7.08L8.82,21.33V1.15a.5.5,0,0,0-1,0v20.2L1.46,13.5a.5.5,0,0,0-.78.62L7.89,23a.5.5,0,0,0,.39.18h.06l.13,0,0,0A.49.49,0,0,0,8.73,23l7.21-8.91A.5.5,0,0,0,15.86,13.42Z"
        transform="translate(-0.57 -0.65)"
      />
    </svg>
  )
}

export default Icon
