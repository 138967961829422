import React from "react"
import classNames from "classnames/bind"
import styled from "@emotion/styled"
import { RichText } from "prismic-reactjs"
import Project from "./project"
import Reveal from "./reveal"
import ButtonLink from "./buttonLink"

function Service({ service, project, variant }) {
  const title = RichText.asText(service.category_title.raw)
  const intro = RichText.asText(service.category_content.raw)
  const slug = service.category_link?.document?.data?.slug
  const more = RichText.asText(service.category_link_label.raw)

  const rowClass = classNames({
    row: true,
  })

  const contentColClass = classNames({
    "col-12 offset-sm-1 col-sm-10 ": true,
    "offset-md-1 col-md-8 offset-lg-2 col-lg-6": variant % 2 === 0,
    "offset-md-3 col-md-8 offset-lg-4 col-lg-6": variant % 2 !== 0,
  })

  const projectColClass = classNames({
    "col-12": true,
    "offset-md-2 col-md-10 offset-lg-3 col-lg-9": variant % 2 === 0,
    "col-md-10 col-lg-9": variant % 2 !== 0,
  })

  const Section = styled.div`
    margin: 100px 0;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: 768px) {
      margin: 20% 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  `

  const Row = styled.div`
    &.row-reverse {
      color: #fff;
    }
  `

  const StyledH3 = styled.h3`
    padding: 5% 10% 0;
  `

  const StyledP = styled.p``

  const BlobWrapper = styled.div`
    max-width: 450px;
    @media (min-width: 768px) {
    }
  `

  return (
    <Reveal>
      <Section className="">
        <Row className={rowClass}>
          <div className="col-12">
            <div className="row text-center">
              <div className={contentColClass}>
                <BlobWrapper className="blob">
                  <div className="blob-wrapper">
                    <div className="blob-content">
                      <StyledH3 className="h1 font-weight-bolder mb-2 mb-md-3">
                        {title}
                      </StyledH3>
                      <StyledP className="mt-3 mt-md-5 mb-2 mb-md-3">
                        {intro}
                      </StyledP>
                      <ButtonLink
                        title={more}
                        to={`/our-approach/${slug}`}
                        className="justify-content-center"
                      />
                    </div>
                  </div>
                </BlobWrapper>
              </div>
            </div>
            {project && (
              <div className="row text-center mt-3 mt-md-6">
                <div className={projectColClass}>
                  <Project project={project} offset={0} />
                </div>
              </div>
            )}
          </div>
        </Row>
      </Section>
    </Reveal>
  )
}

Service.defaultProps = {
  more: "Our approach",
}

Service.propTypes = {}

export default Service
