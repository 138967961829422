import React from "react"

import Slider from "react-slick"
import Img from "gatsby-image"
import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

function InstagramFeed({ className, images }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: true,
    centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 512,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Slider {...settings}>
      {images.map((image, i) => (
        <Img
          key={`${i}`}
          fluid={image.node.childImageSharp.fluid}
          alt="Chunk Creative Agency - Amsterdam | London"
        ></Img>
      ))}
    </Slider>
  )
}

InstagramFeed.defaultProps = {}

InstagramFeed.propTypes = {}

export default InstagramFeed
