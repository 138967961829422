/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Children, useState, useRef, useEffect } from "react"
import Img from "gatsby-image"
import "react-responsive-modal/styles.css"
import VideoModal from "./videoModal"

const Video = React.forwardRef(
  (
    {
      mobileImage,
      desktopImage,
      mobileVideo,
      desktopVideo,
      children,
      videoId,
      className,
    },
    ref
  ) => {
    const [isOpen, setOpen] = useState(false)

    const sources = [
      mobileImage.childImageSharp.fluid,
      {
        ...desktopImage.childImageSharp.fluid,
        media: `(min-width: 768px)`,
      },
    ]
    const videoRef = useRef(null)

    const openModal = () => {
      videoRef && videoRef.current !== null && videoRef.current.pause()
      setOpen(true)
    }
    const closeModal = () => {
      setOpen(false)
      videoRef && videoRef.current !== null && videoRef.current.play()
    }

    useEffect(() => {
      videoRef.current.pause()
      setTimeout(() => {
        videoRef.current.playbackRate = 0.75
        videoRef.current.play()
      }, 2000)
      return () => {}
    }, [videoRef])

    return (
      <>
        <VideoModal
          open={isOpen}
          onCloseModal={closeModal}
          videoId={videoId}
        ></VideoModal>
        <div
          role="button"
          className={`embed-responsive embed-responsive-21by9 embed-responsive-1by1--sm ${className}`}
          onClick={openModal}
          ref={ref}
        >
          <div className="embed-responsive-item">
            <Img
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={sources}
              alt="Chunk Creative Agency - Amsterdam | London"
            />
            <video
              loop
              muted
              playsInline
              className="video"
              id="video--desktop"
              ref={videoRef}
              // poster={desktopImage.childImageSharp.fluid.src}
            >
              <source
                src={desktopVideo}
                media="screen and (min-device-width:769px)"
              />
              <source
                src={mobileVideo}
                media="screen and (max-device-width:768px)"
              />
              <Img
                objectFit="cover"
                objectPosition="50% 50%"
                fluid={sources}
                alt="Chunk Creative Agency - Amsterdam | London"
              />
            </video>
          </div>
          <div className="embed-foreground">{Children.toArray(children)}</div>
        </div>
      </>
    )
  }
)

Video.defaultProps = {}

Video.propTypes = {}

export default Video
