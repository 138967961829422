// Icon
import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const Banner = styled.div`
  padding: 40px;
  background-color: #ccc;
  position: relative;
  .gatsby-image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const StyledBannerTitle = styled.h3`
  margin-bottom: 1px;
`

function PlanBanner(props) {
  const { title, buttonLabel, content } = props

  const data = useStaticQuery(graphql`
    query {
      heroImageDesktop: file(
        relativePath: { eq: "our-plans/ChunkHeader_Desktop_Plans.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroImageMobile: file(
        relativePath: { eq: "our-plans/ChunkHeader_Mobile_Plans.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { heroImageDesktop, heroImageMobile } = data
  const sources = [
    heroImageMobile.childImageSharp.fluid,
    {
      ...heroImageDesktop.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Banner>
      <Img
        objectFit="cover"
        objectPosition="50% 50%"
        fluid={sources}
        alt="Chunk Creative Agency - Amsterdam | London"
      />

      <div className="text-center position-relative">
        <StyledBannerTitle className="h1 font-weight-bolder">
          {title}
        </StyledBannerTitle>
        <p>{content}</p>
        <Link
          className="btn btn-secondary btn-lg text-uppercase mt-2 font-weight-bold"
          to="/our-plans"
        >
          {buttonLabel}
        </Link>
      </div>
    </Banner>
  )
}

export default PlanBanner
